<template lang="pug">
header
  .photo
    img(:src='infos.photo' :alt='infos.nom')
  .perso
    h1 {{ infos.nom }}
    p {{ infos.age }}
  .situ
    p {{ infos.adresse }}<br>{{ infos.code_postal }} {{ infos.ville }}
    p {{ infos.email }}
    p {{ infos.tel }}
  .desc
    p {{ intro }}
.content
  .col-1
    section.formation
      h2 Formation
      ul: li(v-for="formation in formations")
          p.date {{ formation.date }}
          p.name {{ formation.nom }}
    section.langues
      h2 Langues
      ul: li(v-for="langue in langues") {{ langue }}
    section.competences
      h2 Compétences
      ul: li(v-for="competence in competences") {{ competence }}
    section.loisirs
      h2 Loisirs
      ul: li(v-for="loisir in loisirs") {{ loisir }}
  .col-2
    section.experiences
      h2 Experiences
      ul: li(v-for="experience in experiences")
        p.date {{ experience.date }}
        p.name {{ experience.nom }}
        ul: li(v-for="mission in experience.missions") {{ mission }}
    section.benevolat
      h2 Benevolat
      ul: li(v-for="experience in benevolat") {{ experience }}
</template>

<script>
  export default {
    data() {
      return {
        infos: {
          photo: require('./photo.jpg'),
          nom: 'Jeanne de Widerspach',
          age: '14 ans, troisième',
          adresse: '14 Impasse de l\'Écluse',
          code_postal: '69300',
          ville: 'Caluire-et-Cuire',
          email: 'jeanne.widerspach@gmail.com',
          tel: '06 95 81 11 56'
        },
        intro: "J'aime le sport et les valeurs qu'il véhicule. Après avoir découvert l'année dernière le secteur de la décoration et du design, j'aimerais cette année connaître les métiers du secteur sportif lors d'un stage du 11 au 15 avril.",
        formations: [
          {
            nom: 'Collège St-Louis-St-Bruno',
            date: '2018 - 2022'
          },
          {
            nom: 'École des Chartreux',
            date: '2011 - 2018'
          }
        ],
        langues: [
          'Français',
          'Anglais (LV1)',
          'Espagnol (LV2)'
        ],
        competences: [
          'Suite Office',
          'Réseaux sociaux',
          'SketchUp',
          'Gimp',
          'Scratch'
        ],
        loisirs: [
          '8 ans de scoutisme',
          '6 ans de tennis',
          '6 ans de gym',
          '3 ans de théâtre',
          '2 ans de chorale'
        ],
        experiences: [
          {
            date: 'Juin 2021',
            nom: 'SDG Design',
            missions: [
              'Brief client (simulation)',
              'Réalisation de planches d\'ambiance',
              'Réalisation plans 2D et 3D',
              'Prise en main de SketchUp',
              'Veille Pinterest',
              'Réalisation de dossiers de présentation pour le client sur PowerPoint'
            ]
          }
        ],
        benevolat: [
          'Participation pour la 4ème année consécutive à la Course de Héros de Lyon au profit de Pour un Sourire d\'Enfant (course de 6km avec recherche de fonds). Récolte d\'environ 750€ / course.'
        ]
      }
    }
  }
</script>
